import React, { useEffect, useState } from 'react'
import styles from '../../../../../styles/modal/ModalStudent.module.css'
import { useDispatch, useSelector } from 'react-redux';
import { saveProvitionalQuestionsStudentInfo } from '../../../../../app/features/Applicants/applicantsSlice';

export default function TabStudentInformation( {title} ) {

    const { listApplicants, activeApplicant , provitionalQuestionsStudentInformation } = useSelector(state => state.applicants);
    const [answersAcademic,setAnswersAcademic] = useState({
        'questionaryId' : activeApplicant.questionary__student__information.id,
        'emailQuestion' : provitionalQuestionsStudentInformation.emailQuestion ??activeApplicant.questionary__student__information.emailQuestion??null,
        'nationalityQuestion' : provitionalQuestionsStudentInformation.nationalityQuestion ?? activeApplicant.questionary__student__information.nationalityQuestion??null,
        'rbQuestion' : provitionalQuestionsStudentInformation.rbQuestion ?? activeApplicant.questionary__student__information.rbQuestion?? null,
        'fieldRequired': 2
    });

    const dispatch = useDispatch();


    useEffect(() =>{

        dispatch ( saveProvitionalQuestionsStudentInfo ( answersAcademic , 'Saving provitional Questions'));

    },[answersAcademic])


   

    const handleChange =  (e) => {


        setAnswersAcademic({
            ...answersAcademic,
            [e.target.name] : e.target.value
         });

    }

    return (
        <div>

            <div className={styles.boxQuestions}>
                <h1 className={styles.titleQuestions} >{ activeApplicant.questionary__student__information.title }</h1>
            </div>

            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Primer nombre *</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="text" name="emailQuestion"   value={answersAcademic.emailQuestion} onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>

            <div className={styles.rowWhite}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Segundo nombre *</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="text" name="emailQuestion"   value={answersAcademic.emailQuestion} onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>


            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Apellido paterno*</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="text" name="emailQuestion"   value={answersAcademic.emailQuestion} onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>



            <div className={styles.rowWhite}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Apellido materno</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="text" name="emailQuestion"   value={answersAcademic.emailQuestion} onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>

            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Fecha de nacimiento*</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="date" name="emailQuestion"   value={answersAcademic.emailQuestion} onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>

            <div className={styles.rowWhite}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Nacionalidad (es)*</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="text" name="emailQuestion"   value={answersAcademic.emailQuestion} onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>


            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Email*</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="text" name="emailQuestion"   value={answersAcademic.emailQuestion} onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>


            <div className={styles.rowWhite}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Celular*</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="text" name="emailQuestion"   value={answersAcademic.emailQuestion} onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>


            <div className={styles.rowWhite}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>⁠Dirección (Calle, numero, colonia, ciudad, Estado, CP. País)*</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="text" name="emailQuestion"   value={answersAcademic.emailQuestion} onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>


            {/* <div className={styles.rowWhite}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Nationality</label>
                    </div>
                    <div className={styles.inputBox}>
                        <select name="nationalityQuestion"  value={answersAcademic.nationalityQuestion}  onChange={(e) =>  handleChange(e)} id="pet-select">
                            <option value="US">United States</option>
                            <option value="alb">Albania</option>
                            <option value="mex">Mexico</option>
                            <option value="italian">Italian</option>
                            <option value="uk">United Kingdom</option>
                        </select>

                    </div>
                </div>
            </div> */}






            {/* <div className={styles.rowWhite}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Is the applicant a candidate for financial aid? * </label>
                    </div>
                    <div className={styles.inputBox}>
                        <fieldset>

                            <div>
                                <input type="radio" id="huey" onClick={(e) => handleChange(e)} name="rbQuestion" checked={answersAcademic.rbQuestion == 1 ? true : false } value={1}  />
                                <label style={{ marginLeft: '5px' }} for="huey">Yes</label>
                            </div>

                            <div>
                                <input type="radio" id="dewey" onClick={(e) => handleChange(e)} name="rbQuestion" checked={answersAcademic.rbQuestion == 0 ? true : false } value={0}/>
                                <label style={{ marginLeft: '5px' }} for="dewey">No</label>
                            </div>


                        </fieldset>

                    </div>
                </div>
            </div> */}




        </div>
    )
}
