import React, { useEffect, useState } from 'react'
import styles from '../../../../../styles/modal/ModalStudent.module.css'
import { useDispatch, useSelector } from 'react-redux';
import {  saveProvitionalQuestionsStudentProfile } from '../../../../../app/features/Applicants/applicantsSlice';

export default function TabProfileInformation( {title} ) {

    const { listApplicants, activeApplicant , provitionalQuestionsStudentProfile} = useSelector(state => state.applicants);


    const [answersAcademic,setAnswersAcademic] = useState({
        'questionaryId' : activeApplicant.questionary__student__profile.id,
        'gradeQuestion' : provitionalQuestionsStudentProfile.gradeQuestion ?? activeApplicant.questionary__student__profile.gradeQuestion ?? null,
        'yearBorn' : provitionalQuestionsStudentProfile.yearBorn ?? activeApplicant.questionary__student__profile.yearBorn ?? null,
        'nationalityQuestion' : provitionalQuestionsStudentProfile.nationalityQuestion ?? activeApplicant.questionary__student__profile.nationalityQuestion ?? null,
        'fieldRequired': 3
    });

    const dispatch = useDispatch();


    useEffect(() =>{

        dispatch ( saveProvitionalQuestionsStudentProfile ( answersAcademic , 'Saving provitional Questions profile'));

    },[answersAcademic])


   

    const handleChange =  (e) => {


        setAnswersAcademic({
            ...answersAcademic,
            [e.target.name] : e.target.value
         });

    }

    return (
        <div>

            <div className={styles.boxQuestions}>
                <h1 className={styles.titleQuestions} >{ activeApplicant.questionary__student__profile.title }</h1>
            </div>

            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Dominio del idioma Inglés (Hablado y escrito)*</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="text"  value={answersAcademic.gradeQuestion} name="gradeQuestion"  onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>

            <div className={styles.rowWhite}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Dominio del idioma Francés (Hablado y escrito)</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="text"  value={answersAcademic.gradeQuestion} name="gradeQuestion"  onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>

            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Intereses académicos</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="text"  value={answersAcademic.gradeQuestion} name="gradeQuestion"  onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>

            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                    <label>Deportes que practica</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="text"  value={answersAcademic.gradeQuestion} name="gradeQuestion"  onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>


            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                    <label>Actividades extracurriculares que realiza</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="text"  value={answersAcademic.gradeQuestion} name="gradeQuestion"  onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>


            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Intereses que tenga en el arte</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="text"  value={answersAcademic.gradeQuestion} name="gradeQuestion"  onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>

            {/* <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Año nacimiento *</label>
                    </div>
                    <div className={styles.inputBox}>
                    <input type="date" id="start"  name="yearBorn" value={answersAcademic.yearBorn} onChange={(e) =>  handleChange(e)}  />
                    </div>
                </div>
            </div>



          */}


            {/* <div className={styles.rowWhite}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Nationality * </label>
                    </div>
                    <div className={styles.inputBox}>
                        <select name="nationalityQuestion" value={answersAcademic.nationalityQuestion} onChange={(e) =>  handleChange(e)} id="pet-select">
                            <option value="US">United States</option>
                            <option value="alb">Albania</option>
                            <option value="mex">Mexico</option>
                            <option value="italian">Italian</option>
                            <option value="uk">United Kingdom</option>
                        </select>

                    </div>
                </div>
            </div> */}






           




        </div>
    )
}
