import React, { useEffect, useState } from 'react'
import styles from '../../../../../styles/modal/ModalStudent.module.css'

export default function NewStudent( props ,  {title} ) {

  const [newStudent , setNewStudent] = useState({
    name : '' , father_name:'',mother_name:''
  });


  useEffect(() => {

    props.getStudent(newStudent);

  },[newStudent]);


  const savingStudent = ( e) => {
    setNewStudent({
      ...newStudent,
      [e.target.name]:e.target.value
    })
  }

    return (
        <div>

            <div className={styles.boxQuestions}>
                <h1 className={styles.titleQuestions} >{ title }</h1>
            </div>

            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Nombre</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input name="name" onChange={(e) => savingStudent(e)} type="text" />
                    </div>
                </div>
            </div>


            <div className={styles.rowWhite}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Apellido paterno</label>
                    </div>
                    <div className={styles.inputBox}>
                    <input name="father_name" onChange={(e) => savingStudent(e)} type="text" />
                    </div>
                </div>
            </div>



            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Apellido materno</label>
                    </div>
                    <div className={styles.inputBox}>
                    <input name="mother_name" onChange={(e) => savingStudent(e)} type="text" />
                    </div>
                </div>
            </div>



        </div>
    )
}
