import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import modalLogo from '../../../../files/BSM Logo negro fondo transparente.png'
import styles from '../../../../styles/modal/ModalStudent.module.css'
import axios from 'axios';
import Cookies from 'universal-cookie';
import { useDispatch } from 'react-redux';
import { setApplicantsList } from '../../../../app/features/Applicants/applicantsSlice';


export default function NewStudentModal( props ) {

  const dispatch = useDispatch();


  const cookies = new Cookies();

  const userLogged = cookies.get('UserLogged')


    const [newStudent , setNewStudent] = useState({
        name : '' , father_name:'',mother_name:'',id_user : userLogged?.id ?? null
      });
    
    const handleClose = () => {
        
      props.closeModalNewStudent(false);
    }

    const savingStudent = (e) => {
        setNewStudent({
            ...newStudent,
            [e.target.name]:e.target.value
          })
    }


    const saveOnApiStudent = async () => {
      try {
      const response =  await axios.post('https://xtendapi.boardingschools.mx/api/applicants/saveApplicant',newStudent);

      dispatch ( setApplicantsList(response.data ,'ListApplicants') )

      alert('Aplicante creado con exito.')
      
      props.closeModalNewStudent(false);
          
        } catch (error) {
           alert ( 'Ocurrio un error');
        }

    }
  return (
    <Modal show={props.openModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <div className={styles.backgroundImageModal}>
            <img src={modalLogo} width={450} height={450} alt="Background modal image" />
          </div>

        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <div className={styles.boxContent}>

        <div className={styles.boxQuestions}>
                <h1 style={{marginTop:'10px'}}className={styles.titleQuestions} > New Aplicant</h1>
            </div>

            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Nombre *</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input name="name" onChange={(e) => savingStudent(e)} type="text" />
                    </div>
                </div>
            </div>


            <div className={styles.rowWhite}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Apellido paterno *</label>
                    </div>
                    <div className={styles.inputBox}>
                    <input name="father_name" onChange={(e) => savingStudent(e)} type="text" />
                    </div>
                </div>
            </div>



            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Apellido materno *</label>
                    </div>
                    <div className={styles.inputBox}>
                    <input name="mother_name" onChange={(e) => savingStudent(e)} type="text" />
                    </div>
                </div>
            </div>

          <hr />
          <div className={styles.centerDiv}>
          <button  disabled={ (newStudent.name && newStudent.father_name && newStudent.mother_name) ? false : true } onClick={(e) => saveOnApiStudent()}  type="submit" 
          className={(newStudent.name && newStudent.father_name && newStudent.mother_name) ? styles.buttonSaveAndContinue : styles.buttonSaveAndContinueDisabled}>Save Aplicant</button>
          </div>

        </div>

      </Modal.Body>
      <Modal.Footer>
        <Button className={styles.modalCloseBtn} onClick={handleClose}>
          X
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
