import React, { useEffect, useState } from 'react'
import styles from '../../../../../styles/modal/ModalStudent.module.css'
import { useDispatch, useSelector } from 'react-redux';
import { saveProvitionalQuestionsFamily } from '../../../../../app/features/Applicants/applicantsSlice';

export default function TabFamilyInformation( {title} ) {

    const { listApplicants, activeApplicant , provitionalQuestionsFamily } = useSelector(state => state.applicants);
    const [answersAcademic,setAnswersAcademic] = useState({
        'questionaryId' : activeApplicant.questionary__family__information.id,
        'numberFamily' : provitionalQuestionsFamily.numberFamily ?? activeApplicant.questionary__family__information.numberFamily ?? null,
        'fieldRequired': 2
    });

    const dispatch = useDispatch();


    useEffect(() =>{

        dispatch ( saveProvitionalQuestionsFamily ( answersAcademic , 'Saving provitional Questions Family'));

    },[answersAcademic])


   

    const handleChange =  (e) => {


        setAnswersAcademic({
            ...answersAcademic,
            [e.target.name] : e.target.value
         });

    }

    return (
        <div>

            <div className={styles.boxQuestions}>
                <h1 className={styles.titleQuestions} >{ activeApplicant.questionary__family__information.title }</h1>
            </div>

            <span style={{display:'flex',justifyContent:'flex-start',marginTop:'10px',marginBottom:'15px',fontSize:'24px'}}>Información del padre/guardián 1</span>
          
            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Nombre(es)* </label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="text" name="numberFamily" value={answersAcademic.numberFamily}  onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>

            <div className={styles.rowWhite}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Primer apellido* </label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="text" name="numberFamily" value={answersAcademic.numberFamily}  onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>

            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Segundo apellido* </label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="text" name="numberFamily" value={answersAcademic.numberFamily}  onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>

            <div className={styles.rowWhite}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Relacion con el estudiante* </label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="text" name="numberFamily" value={answersAcademic.numberFamily}  onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>

            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Correo* </label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="text" name="numberFamily" value={answersAcademic.numberFamily}  onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>

            <div className={styles.rowWhite}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Celular* </label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="phone" name="numberFamily" value={answersAcademic.numberFamily}  onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>

            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Ocupación* </label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="text" name="numberFamily" value={answersAcademic.numberFamily}  onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>

            <div className={styles.rowWhite}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Empleador </label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="text" name="numberFamily" value={answersAcademic.numberFamily}  onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>



            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Estado civil* </label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="text" name="numberFamily" value={answersAcademic.numberFamily}  onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>



            <div className={styles.rowWhite}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Dirección </label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="text" name="numberFamily" value={answersAcademic.numberFamily}  onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>


            <span style={{display:'flex',justifyContent:'flex-start',marginTop:'10px',marginBottom:'15px',fontSize:'24px'}}>
                Información del padre/guardián 2</span>
          
          <div className={styles.rowGray}>
              <div className={styles.insideGray}>

                  <div className={styles.labelName}>
                      <label>Nombre(es)* </label>
                  </div>
                  <div className={styles.inputBox}>
                      <input type="text" name="numberFamily" value={answersAcademic.numberFamily}  onChange={(e) =>  handleChange(e)} />
                  </div>
              </div>
          </div>

          <div className={styles.rowWhite}>
              <div className={styles.insideGray}>

                  <div className={styles.labelName}>
                      <label>Primer apellido* </label>
                  </div>
                  <div className={styles.inputBox}>
                      <input type="text" name="numberFamily" value={answersAcademic.numberFamily}  onChange={(e) =>  handleChange(e)} />
                  </div>
              </div>
          </div>

          <div className={styles.rowGray}>
              <div className={styles.insideGray}>

                  <div className={styles.labelName}>
                      <label>Segundo apellido* </label>
                  </div>
                  <div className={styles.inputBox}>
                      <input type="text" name="numberFamily" value={answersAcademic.numberFamily}  onChange={(e) =>  handleChange(e)} />
                  </div>
              </div>
          </div>

          <div className={styles.rowWhite}>
              <div className={styles.insideGray}>

                  <div className={styles.labelName}>
                      <label>Relacion con el estudiante* </label>
                  </div>
                  <div className={styles.inputBox}>
                      <input type="text" name="numberFamily" value={answersAcademic.numberFamily}  onChange={(e) =>  handleChange(e)} />
                  </div>
              </div>
          </div>

          <div className={styles.rowGray}>
              <div className={styles.insideGray}>

                  <div className={styles.labelName}>
                      <label>Correo* </label>
                  </div>
                  <div className={styles.inputBox}>
                      <input type="text" name="numberFamily" value={answersAcademic.numberFamily}  onChange={(e) =>  handleChange(e)} />
                  </div>
              </div>
          </div>

          <div className={styles.rowWhite}>
              <div className={styles.insideGray}>

                  <div className={styles.labelName}>
                      <label>Celular* </label>
                  </div>
                  <div className={styles.inputBox}>
                      <input type="phone" name="numberFamily" value={answersAcademic.numberFamily}  onChange={(e) =>  handleChange(e)} />
                  </div>
              </div>
          </div>

          <div className={styles.rowGray}>
              <div className={styles.insideGray}>

                  <div className={styles.labelName}>
                      <label>Ocupación* </label>
                  </div>
                  <div className={styles.inputBox}>
                      <input type="text" name="numberFamily" value={answersAcademic.numberFamily}  onChange={(e) =>  handleChange(e)} />
                  </div>
              </div>
          </div>

          <div className={styles.rowWhite}>
              <div className={styles.insideGray}>

                  <div className={styles.labelName}>
                      <label>Empleador </label>
                  </div>
                  <div className={styles.inputBox}>
                      <input type="text" name="numberFamily" value={answersAcademic.numberFamily}  onChange={(e) =>  handleChange(e)} />
                  </div>
              </div>
          </div>



          <div className={styles.rowGray}>
              <div className={styles.insideGray}>

                  <div className={styles.labelName}>
                      <label>Estado civil* </label>
                  </div>
                  <div className={styles.inputBox}>
                      <input type="text" name="numberFamily" value={answersAcademic.numberFamily}  onChange={(e) =>  handleChange(e)} />
                  </div>
              </div>
          </div>



          <div className={styles.rowWhite}>
              <div className={styles.insideGray}>

                  <div className={styles.labelName}>
                      <label>Dirección </label>
                  </div>
                  <div className={styles.inputBox}>
                      <input type="text" name="numberFamily" value={answersAcademic.numberFamily}  onChange={(e) =>  handleChange(e)} />
                  </div>
              </div>
          </div>


         

          <span style={{display:'flex',justifyContent:'flex-start',marginTop:'10px',marginBottom:'15px',fontSize:'24px'}}>
                Hermanos</span>
          
          <div className={styles.rowGray}>
              <div className={styles.insideGray}>

                  <div className={styles.labelName}>
                      <label>Nombre y apellidos</label>
                  </div>
                  <div className={styles.inputBox}>
                      <input type="text" name="numberFamily" value={answersAcademic.numberFamily}  onChange={(e) =>  handleChange(e)} />
                  </div>
              </div>
          </div>

          <div className={styles.rowWhite}>
              <div className={styles.insideGray}>

                  <div className={styles.labelName}>
                      <label>Edad </label>
                  </div>
                  <div className={styles.inputBox}>
                      <input type="text" name="numberFamily" value={answersAcademic.numberFamily}  onChange={(e) =>  handleChange(e)} />
                  </div>
              </div>
          </div>

          <div className={styles.rowGray}>
              <div className={styles.insideGray}>

                  <div className={styles.labelName}>
                      <label>Grado actual </label>
                  </div>
                  <div className={styles.inputBox}>
                      <input type="text" name="numberFamily" value={answersAcademic.numberFamily}  onChange={(e) =>  handleChange(e)} />
                  </div>
              </div>
          </div>







        </div>
    )
}
