import React, { useEffect, useState } from 'react'
import styles from '../../../../../styles/modal/ModalStudent.module.css'
import { useDispatch, useSelector } from 'react-redux';
import { saveProvitionalQuestionsAcademic } from '../../../../../app/features/Applicants/applicantsSlice';

export default function TabAcademicInformation( {title} ) {

    const { listApplicants, activeApplicant , provitionalQuestionsAcademic } = useSelector(state => state.applicants);
    const [answersAcademic,setAnswersAcademic] = useState({
        'questionaryId' : activeApplicant.questionary__academic__information.id,
        'collegeQuestion' : provitionalQuestionsAcademic.collegeQuestion ?? activeApplicant.questionary__academic__information.collegeQuestion ?? null,
        'locationQuestion' : provitionalQuestionsAcademic.locationQuestion ?? activeApplicant.questionary__academic__information.locationQuestion ?? null,
        'rbForeignQuestion' : provitionalQuestionsAcademic.rbForeignQuestion ?? activeApplicant.questionary__academic__information.rbForeignQuestion ?? null,
        'fieldRequired': 1
    });

    const dispatch = useDispatch();


    useEffect(() =>{

        dispatch ( saveProvitionalQuestionsAcademic ( answersAcademic , 'Saving provitional Questions Academic'));

    },[answersAcademic])


   

    const handleChange =  (e) => {


        setAnswersAcademic({
            ...answersAcademic,
            [e.target.name] : e.target.value
         });

    }

    return (
        <div>

            <div className={styles.boxQuestions}>
                <h1 className={styles.titleQuestions} >{ activeApplicant.questionary__academic__information.title }</h1>
            </div>

            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Nombre de colegio actual *</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="email" name="collegeQuestion"  value={answersAcademic.collegeQuestion} onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>



            <div className={styles.rowWhite}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Fecha de ingreso a la institución *</label>
                    </div>
                    <div className={styles.inputBox}>
                    <input type="date" id="start"  name="yearBorn" value={answersAcademic.yearBorn} onChange={(e) =>  handleChange(e)}  />
                    </div>
                </div>
            </div>

            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>⁠Idioma de instrucción en el colegio actual*</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="email" name="collegeQuestion"  value={answersAcademic.collegeQuestion} onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>

            <div className={styles.rowWhite}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>⁠Dirección</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="email" name="collegeQuestion"  value={answersAcademic.collegeQuestion} onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>

            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Nombre del director</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="email" name="collegeQuestion"  value={answersAcademic.collegeQuestion} onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>

            <div className={styles.rowWhite}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Grado actual*</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="email" name="collegeQuestion"  value={answersAcademic.collegeQuestion} onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>

            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Grado que desea aplicar*</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="email" name="collegeQuestion"  value={answersAcademic.collegeQuestion} onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>

            <div className={styles.rowWhite}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label> Ciclo escolar en que desee aplicar*</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="email" name="collegeQuestion"  value={answersAcademic.collegeQuestion} onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>


            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Tiempo de estudio*</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="email" name="collegeQuestion"  value={answersAcademic.collegeQuestion} onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>


            <div className={styles.rowWhite}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>He repetido año ( grado que repitio ) </label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="email" name="collegeQuestion"  value={answersAcademic.collegeQuestion} onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>


            <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Nombre y grados de estudios en colegios anteriores</label>
                    </div>
                    <div className={styles.inputBox}>
                        <input type="email" name="collegeQuestion"  value={answersAcademic.collegeQuestion} onChange={(e) =>  handleChange(e)} />
                    </div>
                </div>
            </div>




            {/* <div className={styles.rowGray}>
                <div className={styles.insideGray}>

                    <div className={styles.labelName}>
                        <label>Ha repetido año ? </label>
                    </div>
                    <div className={styles.inputBox}>
                        <fieldset>

                            <div>
                                <input type="radio" id="huey" onClick={(e) => handleChange(e)} name="rbForeignQuestion" value="true" checked={answersAcademic.rbForeignQuestion ?? 0}  />
                                <label style={{ marginLeft: '5px' }} for="huey">Yes</label>
                            </div>

                            <div>
                                <input type="radio" id="dewey" onClick={(e) => handleChange(e)} name="rbForeignQuestion" value="false" checked={answersAcademic.rbForeignQuestion == true ? false : true} />
                                <label style={{ marginLeft: '5px' }} for="dewey">No</label>
                            </div>


                        </fieldset>

                    </div>
                </div>
            </div> */}




        </div>
    )
}
