import styles from '../../../styles/Landing/Landing.module.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarsProgress, faExclamation, faExclamationCircle, faMailBulk, faPencil } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import ModalStudent from './modalsQuestions/ModalStudent';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { setActiveApplicant } from '../../../app/features/Applicants/applicantsSlice';



export default function Questions() {
    const dispatch = useDispatch();

    const { activeApplicant } = useSelector(state => state.applicants);

    const [currentHeader, setCurrentHeader] = useState('Inf. Academica');


    const [modalStudent, setModalStudent] = useState(false);

    const openModalStudent = (header) => {
        setCurrentHeader(header);
        setModalStudent(!modalStudent);
    }
    const closeModal = async () => {


        try {
            const response = await axios.post('https://xtendapi.boardingschools.mx/api/applicants/getUpdatedApplicant', { id_applicant: activeApplicant.id });
            dispatch(setActiveApplicant(response.data[0], 'Activate Applicant'))


            // dispatch(setApplicantsList(response.data, 'ListApplicants'))

            // alert('Aplicante creado con exito.')

            // props.closeModalNewStudent(false);

        } catch (error) {
            alert('Ocurrio un error');
        }

        setModalStudent(!modalStudent);
    }

    return (
        <>
            <ModalStudent closeModal={closeModal} header={currentHeader} openModal={modalStudent} />
            <div className={styles.questionsBox}>

                <div className={styles.firstColumnQuestion}>

                    <h1 className={styles.titleQuestion}>   <FontAwesomeIcon
                        icon={faBarsProgress}
                    />  {activeApplicant.questionary__academic__information.title}</h1>

                </div>



                <div >
                    {activeApplicant.questionary__academic__information.isComplete ?

                        <span className={styles.optionalField}>
                            &nbsp;
                            Complete
                        </span>

                        :

                        <span className={styles.lastColumnQuestion}>
                            <FontAwesomeIcon
                                icon={faExclamationCircle}
                            />&nbsp;
                            Pendiente
                        </span>

                    }
                </div>
                <span >
                    Lorem ipsum dolor sit amet
                    consectetur adipisicing elit. Nesciunt quisquam
                    quod quam autem voluptatibus maiores ducimus modi,
                    mollitia saepe nobis sit corporis sequi exercitationem a ad illo? Nemo, explicabo dolorum.
                </span>
                <button onClick={(e) => openModalStudent('Inf. Academica')} className={styles.buttonCompleteInfo}>
                    <FontAwesomeIcon
                        icon={faPencil}
                    />&nbsp;Complete your {activeApplicant.questionary__academic__information.title}</button>
            </div>
            <div className={styles.questionsBox}>

                <div className={styles.firstColumnQuestion}>

                    <h1 className={styles.titleQuestion}>   <FontAwesomeIcon
                        icon={faBarsProgress}
                    />  {activeApplicant.questionary__family__information.title}</h1>

                </div>



                <div >
                    {activeApplicant.questionary__family__information.isComplete ?

                        <span className={styles.optionalField}>
                            &nbsp;
                            Complete
                        </span>

                        :

                        <span className={styles.lastColumnQuestion}>
                            <FontAwesomeIcon
                                icon={faExclamationCircle}
                            />&nbsp;
                            Pendiente
                        </span>

                    }
                </div>
                <span >
                    Lorem ipsum dolor sit amet
                    consectetur adipisicing elit. Nesciunt quisquam
                    quod quam autem voluptatibus maiores ducimus modi,
                    mollitia saepe nobis sit corporis sequi exercitationem a ad illo? Nemo, explicabo dolorum.
                </span>

                <button onClick={(e) => openModalStudent('Inf. Familiar')} className={styles.buttonCompleteInfo}>
                    <FontAwesomeIcon
                        icon={faPencil}
                    />&nbsp;Complete your {activeApplicant.questionary__family__information.title}</button>
            </div>


            <div className={styles.questionsBox}>

                <div className={styles.firstColumnQuestion}>

                    <h1 className={styles.titleQuestion}>   <FontAwesomeIcon
                        icon={faBarsProgress}
                    />  {activeApplicant.questionary__student__information.title}</h1>

                </div>



                <div >
                    {activeApplicant.questionary__student__information.isComplete ?

                        <span className={styles.optionalField}>
                            &nbsp;
                            Complete
                        </span>

                        :

                        <span className={styles.lastColumnQuestion}>
                            <FontAwesomeIcon
                                icon={faExclamationCircle}
                            />&nbsp;
                            Pendiente
                        </span>

                    }
                </div>
                <span >
                    Lorem ipsum dolor sit amet
                    consectetur adipisicing elit. Nesciunt quisquam
                    quod quam autem voluptatibus maiores ducimus modi,
                    mollitia saepe nobis sit corporis sequi exercitationem a ad illo? Nemo, explicabo dolorum.
                </span>
                <button onClick={(e) => openModalStudent('Inf. Estudiante')} className={styles.buttonCompleteInfo}>
                    <FontAwesomeIcon
                        icon={faPencil}
                    />&nbsp;Complete your {activeApplicant.questionary__student__information.title}</button>
            </div>

            <div className={styles.questionsBox}>

                <div className={styles.firstColumnQuestion}>

                    <h1 className={styles.titleQuestion}>   <FontAwesomeIcon
                        icon={faBarsProgress}
                    />  {activeApplicant.questionary__student__profile.title}</h1>

                </div>



                <div >
                    {activeApplicant.questionary__student__profile.isComplete ?

                        <span className={styles.optionalField}>
                            &nbsp;
                            Complete
                        </span>

                        :

                        <span className={styles.lastColumnQuestion}>
                            <FontAwesomeIcon
                                icon={faExclamationCircle}
                            />&nbsp;
                            Pendiente
                        </span>

                    }
                </div>
                <span >
                    Lorem ipsum dolor sit amet
                    consectetur adipisicing elit. Nesciunt quisquam
                    quod quam autem voluptatibus maiores ducimus modi,
                    mollitia saepe nobis sit corporis sequi exercitationem a ad illo? Nemo, explicabo dolorum.
                </span>
                <button onClick={(e) => openModalStudent('Perfil')} className={styles.buttonCompleteInfo}>
                    <FontAwesomeIcon
                        icon={faPencil}
                    />&nbsp;Complete your {activeApplicant.questionary__student__information.title}</button>
            </div>



            {/* HEEEEEEEEEEEEEEEEEEEEEEEEE */}

            <div style={{display:'flex',justifyContent:'right'}} >
                {
                (activeApplicant.questionary__student__profile.isComplete && activeApplicant.questionary__academic__information.isComplete && activeApplicant.questionary__family__information.isComplete
                    && activeApplicant.questionary__student__information.isComplete
                 ) ?

                 <span onClick={(e) => alert ( 'Enviando por correo...' )} style={{marginBottom:'10px',background:'purple'}}
                 className={styles.lastColumnQuestion}>
                     <FontAwesomeIcon
                         icon={faMailBulk}
                     />&nbsp;
                     Enviar por correo
                 </span>

                    :

                    <span  disabled style={{marginBottom:'10px',background:'gray' , cursor:'none'}}
                    className={styles.lastColumnQuestion}>
                        <FontAwesomeIcon
                            icon={faMailBulk}
                        />&nbsp;
                        Enviar por correo
                    </span>

                }
            </div>


        </>

    )
}
