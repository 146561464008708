import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import modalLogo from '../../../../files/BSM Logo negro fondo transparente.png'
import styles from '../../../../styles/modal/ModalStudent.module.css'
import NewStudent from './tabsModalStudent/NewStudent';
import TabAcademicInformation from './tabsModalStudent/TabAcademicInformation';
import { useSelector } from 'react-redux';
import TabStudentInformation from './tabsModalStudent/TabStudentInformation';
import TabFamilyInformation from './tabsModalStudent/TabFamilyInformation';
import TabProfileInformation from './tabsModalStudent/TabProfileInformation';
import axios from 'axios';

export default function ModalStudent(props) {

  const { provitionalQuestionsAcademic, provitionalQuestionsStudentInformation ,provitionalQuestionsFamily ,provitionalQuestionsStudentProfile } = useSelector(state => state.applicants);


  useEffect(() => {
    setHeaderActive(props.header);

  }, [props.header])


  const saveAnswers = async ( url , obj) => {

    try {
      const response = await axios.post('https://xtendapi.boardingschools.mx/api/applicants/'+url, obj);

      // dispatch(setApplicantsList(response.data, 'ListApplicants'))

      // alert('Aplicante creado con exito.')

      // props.closeModalNewStudent(false);

  } catch (error) {
      alert('Ocurrio un error');
  }

  }

  const [ newStudent , setNewStudent] = useState({});

  const handleClose = () => {
    // props.getStudent(props.getStudent);
    saveAnswers('provitionalQuestionsAcademic',provitionalQuestionsAcademic);
    saveAnswers('provitionalQuestionsStudentInformation',provitionalQuestionsStudentInformation);
    saveAnswers('provitionalQuestionsFamily',provitionalQuestionsFamily);
    saveAnswers('provitionalQuestionsStudentProfile',provitionalQuestionsStudentProfile);
    props.closeModal(newStudent);

  }
  const headers = ['Inf. Academica','Inf. Estudiante',  'Inf. Familiar', 'Perfil'];
  const [headerActive , setHeaderActive] = useState('Inf. Academica');

  const lookingNextHeader = () => {

    if(headerActive == headers[0]){

      if(provitionalQuestionsAcademic.collegeQuestion == "" || provitionalQuestionsAcademic.collegeQuestion == null){
        alert( ' Faltan preguntas obligatorias por responder')
      } else {

        const obj = {...provitionalQuestionsAcademic,isComplete:true}
        // guardar info provisional
        saveAnswers('provitionalQuestionsAcademic',obj);
        setHeaderActive(headers[1]);
    }
  }

  if(headerActive == headers[1]){

    if(provitionalQuestionsStudentInformation.emailQuestion == "" || provitionalQuestionsStudentInformation.emailQuestion == null || provitionalQuestionsStudentInformation.rbQuestion == null){
      
      alert( 'Faltan preguntas obligatorios por responder');
      
    } else{
                // guardar info provisional
                const obj = {...provitionalQuestionsStudentInformation,isComplete:true}
          saveAnswers('provitionalQuestionsStudentInformation',obj);


      setHeaderActive(headers[2]);
    }
   
  }
  else if (headerActive == headers[1]) {
    console.log('HOLA')
  }

  if(headerActive == headers[2]){
        
    //Guardar info provisional
    const obj = {...provitionalQuestionsFamily,isComplete:provitionalQuestionsFamily.numberFamily != null ? true : false}

    saveAnswers('provitionalQuestionsFamily',obj);

    setHeaderActive(headers[3]);

  } 
  if(headerActive == headers[3]){

    const obj = {...provitionalQuestionsStudentProfile,isComplete:true}

          saveAnswers('provitionalQuestionsStudentProfile',obj);
    handleClose();  
  }

  }

  const selectHeader = (header) => {

    setHeaderActive(header);

    // No validations , temporal

    // if(header != headerActive){

    //         // Validacion informacion academica 

    //   if(headerActive == headers[0]){

    //     if(provitionalQuestionsAcademic.collegeQuestion == "" || provitionalQuestionsAcademic.collegeQuestion == null){
    //       alert( ' Faltan preguntas obligatorias por responder')
    //     } else {

    //       const obj = {...provitionalQuestionsAcademic,isComplete:true}
    //       // guardar info provisional
    //       saveAnswers('provitionalQuestionsAcademic',obj);
    //       setHeaderActive(header);

    //     }
    //   }

    //   // Validacion informacion estudiante 

    //   if(headerActive == headers[1]){

    //     if(provitionalQuestionsStudentInformation.emailQuestion == "" || provitionalQuestionsStudentInformation.emailQuestion == null || provitionalQuestionsStudentInformation.rbQuestion == null){
          
    //       alert( 'Faltan preguntas obligatorios por responder');
          
    //     } else{
    //                 // guardar info provisional
    //                 const obj = {...provitionalQuestionsStudentInformation,isComplete:true}
    //           saveAnswers('provitionalQuestionsStudentInformation',obj);


    //       setHeaderActive(header);
    //     }
       
    //   }
    //   else if (headerActive == headers[1]) {
    //     console.log('HOLA')
    //   }


     

    //   if(headerActive == headers[2]){
        
    //     //Guardar info provisional
    //     const obj = {...provitionalQuestionsFamily,isComplete:provitionalQuestionsFamily.numberFamily != null ? true : false}

    //     saveAnswers('provitionalQuestionsFamily',obj);

    //     setHeaderActive(header);

    //   }

    //   if(headerActive == headers[3]){

    //     if(provitionalQuestionsStudentProfile.gradeQuestion == "" || provitionalQuestionsStudentProfile.gradeQuestion == null
          
    //       || provitionalQuestionsStudentProfile.yearBorn == null || provitionalQuestionsStudentProfile.nationalityQuestion == null
          
    //       )
    //       {
          
    //       alert( 'Faltan preguntas obligatorios por responder');
          
    //     } else{
            
    //       // guardar info provisional
    //       const obj = {...provitionalQuestionsStudentProfile,isComplete:true}

    //       saveAnswers('provitionalQuestionsStudentProfile',obj);
    //       setHeaderActive(header);
    //     }

    //   }

    // }
    // setHeaderActive(header);

    // setHeaderActive(header);
  }

  const getStudent = (e ) => {

    setNewStudent( (e.name?? '' ) + ' ' + (e.father_name?? ' ') + ' ' +  (e.mother_name?? '') );
  }

  const renderSwitchHeader = (header , isNew = false) => {

    switch(header){
      case 'Inf. Estudiante':

        return <TabStudentInformation title={header}/>;
        
        case 'Inf. Academica':
        return <TabAcademicInformation title={header}/>;


      case 'Inf. Familiar':
        return <TabFamilyInformation title={header} />;

      case 'Perfil':
        return <TabProfileInformation title={header} />;

        default:
          return <NewStudent getStudent={getStudent} title={'New Student'} />;
    
    }

  }
  return (
    <Modal show={props.openModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <div className={styles.backgroundImageModal}>
            <img src={modalLogo} width={450} height={450} alt="Background modal image" />
          </div>

        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <div className={styles.boxContent}>

         {!props.newStudent &&
          <div className={styles.headerBox}>
          {headers.map(header => {
            return (
              <button onClick={(e) => selectHeader(header) } className={ headerActive == header ? styles.headerActive : styles.header}>
                {header}
              </button>
            )
          })}

        </div>
        }

          {
            renderSwitchHeader(props.newStudent ? 'New Student ' : headerActive )
          }

          <hr />
          <div className={styles.centerDiv}>
          <button  onClick={(e) => lookingNextHeader(e)} className={styles.buttonSaveAndContinue}>Save and Continue</button>
          </div>

        </div>

      </Modal.Body>
      <Modal.Footer>
        <Button className={styles.modalCloseBtn} onClick={handleClose}>
          X
        </Button>
      </Modal.Footer>
    </Modal>

  )
}
